import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Container, Nav, Navbar } from 'react-bootstrap'
import Banner from './banner'
import Helmet from 'react-helmet';

export default ({ bannerConfig, title = '', children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>{title || data.site.siteMetadata.title}</title>
      </Helmet>
      <header>
        <Navbar bg="light" expand="lg">
          <Container>
            <Link to="/">
              <Navbar.Brand>{data.site.siteMetadata.title}</Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto" as="ul">
                <Nav.Item as="li">
                  <Link className="nav-link" activeClassName="active" to="/">
                    Home
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/about"
                  >
                    About
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/books"
                  >
                    Books
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/sparkles"
                  >
                    Sparkles
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/blog"
                  >
                    Blog
                  </Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      {bannerConfig && <Banner config={bannerConfig} />}
      <Container className="my-3">{children}</Container>
    </>
  )
}
